<template>

  <div v-if="usersData">
    <b-overlay
      :show="showOverlay"
      rounded="sm"
      @shown="onShown"
      @hidden="onHidden"
    >
      <template #overlay>
        <div class="text-center">
          <b-icon
            icon="stopwatch"
            font-scale="3"
            animation="cylon"
          />
          <p id="cancel-label">
            {{ $t('Lütfen Bekleyiniz...') }}
          </p>
          <div class="progress-wrapper">
            <b-card-text class="mb-0">
              Servise İletiliyor... {{ postUploadingProgress+'%' }}
            </b-card-text>
            <b-progress
              v-model="postUploadingProgress"
              max="100"
            />
          </div>
        </div></template>
      <b-modal
        id="modal-1"
        :title="$t('E-Posta Önizlemesi')"
        size="xl"
        no-footer
      >
        <email-preview :message="mailData" />
        <hr>

      </b-modal>

      <b-row>
        <b-col md="12">
          <b-card
            class="mb-0"
          >

            <b-row>
              <b-col md="12">
                <!-- BODY -->
                <validation-observer
                  #default="{ handleSubmit }"
                  ref="refFormObserver"
                >
                  <!-- Form -->
                  <b-form
                    v-if="mailData"
                    class="p-2"
                    @submit.prevent="handleSubmit(saveData)"
                    @reset.prevent="resetForm"
                  >

                    <b-form-group
                      v-if="usersData"
                      :label="$t('E Maili Bu Satış Temsilcisi Üzerinden Gönder')"
                      label-for="user_id"
                    >

                      <v-select
                        id="user_id"
                        v-model="mailData.from_user"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="usersData"
                        :reduce="val => val.value"
                        :clearable="false"
                        input-id="user_id"
                      />
                    </b-form-group>
                    <b-form-group
                      v-if="usersData"
                      :label="$t('Gönderime Eklenecek E-Mail Kategorileri')"
                      label-for="selected_categories"
                    >
                      <b-row>
                        <b-col md="9">
                          <v-select
                            id="selected_categories"
                            v-model="mailData.selected_categories"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            multiple
                            :options="emailCategoryData"
                            :reduce="val => val.value"
                            :clearable="false"
                            input-id="selected_categories"
                          />
                        </b-col>
                        <b-col md="3">
                          <b-button
                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                            variant="outline-primary"
                            @click="selectAllCategories(true)"
                          >
                            <feather-icon
                              icon="HomeIcon"
                              class="mr-50"
                            />
                            <span class="align-middle">{{ $t('Tümünü Seç') }}</span>
                          </b-button>
                          <b-button
                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                            variant="outline-primary"
                            @click="selectAllCategories(false)"
                          >
                            <feather-icon
                              icon="HomeIcon"
                              class="mr-50"
                            />
                            <span class="align-middle">{{ $t('Seçimleri Kaldır') }}</span>
                          </b-button>
                        </b-col>
                      </b-row>

                    </b-form-group>
                    <b-form-group
                      v-if="usersData"
                      :label="$t('Bu Ülkelerdeki Firmaları Dahil Et')"
                      label-for="selected_countries"
                    >
                      <b-row>
                        <b-col md="9">
                          <v-select
                            id="selected_countries"
                            v-model="mailData.selected_countries"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            multiple
                            :options="countryOptions"
                            :reduce="val => val.value"
                            :clearable="false"
                            input-id="selected_countries"
                          />
                        </b-col>
                        <b-col md="3">

                          <b-button
                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                            variant="outline-primary"
                            @click="selectAllCountries(true)"
                          >
                            <feather-icon
                              icon="HomeIcon"
                              class="mr-50"
                            />
                            <span class="align-middle">{{ $t('Tümünü Seç') }}</span>
                          </b-button>
                          <b-button
                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                            variant="outline-primary"
                            @click="selectAllCountries(false)"
                          >
                            <feather-icon
                              icon="HomeIcon"
                              class="mr-50"
                            />
                            <span class="align-middle">{{ $t('Seçimleri Kaldır') }}</span>
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-form-group>
                    <b-form-group
                      v-if="usersData"
                      :label="$t('Bu Temsilcilerin Firmalarını Dahil Et')"
                      label-for="selected_user_customers"
                    >
                      <b-row>
                        <b-col md="9">
                          <v-select
                            id="selected_user_customers"
                            v-model="mailData.selected_user_customers"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            multiple
                            :options="usersData"
                            :reduce="val => val.value"
                            :clearable="false"
                            input-id="selected_user_customers"
                          />
                        </b-col>
                        <b-col md="3">

                          <b-button
                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                            variant="outline-primary"
                            @click="selectAllUsers(true)"
                          >
                            <feather-icon
                              icon="HomeIcon"
                              class="mr-50"
                            />
                            <span class="align-middle">{{ $t('Tümünü Seç') }}</span>
                          </b-button>
                          <b-button
                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                            variant="outline-primary"
                            @click="selectAllUsers(false)"
                          >
                            <feather-icon
                              icon="HomeIcon"
                              class="mr-50"
                            />
                            <span class="align-middle">{{ $t('Seçimleri Kaldır') }}</span>
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-form-group>
                    <validation-provider
                      #default="validationContext"
                      :name="$t('Gönderim Zamanı')"
                      rules="required"
                    >
                      <b-form-group
                        :label="$t('Gönderim Zamanı')"
                        label-for="send_time"
                      >
                        <flat-pickr
                          v-model="mailData.send_time"
                          class="form-control"
                          :config="{ enableTime: true,dateFormat: 'Y-m-d H:i'}"
                        />

                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                    <b-form-group

                      :label="$t('Ek E-Mail Adresleri')"
                      label-for="email_address_list"
                    >
                      <b-form-tags
                        v-model="mailData.email_address_list"
                        input-id="tags-email_address_list"
                        :input-attrs="{ 'aria-describedby': 'tags-validation-help' }"

                        :placeholder="$t('E Posta Adresi Girip Ardından Enter Tuşuna Basınız..')"
                        :tag-validator="emailListValidation"
                        separator=" "
                      />
                      <!-- The following slots are for b-form-group -->
                      <template #invalid-feedback>
                        {{ $t('Sadece E Posta Formatında Veri Giriniz') }}
                      </template>
                      <template #description>
                        <div id="tags-validation-help">
                          {{ $t('Gönderim listesine eklemek istediğiniz e posta adresini kutucuğa yazıp enter tuşuna basarak daha fazla adres ekleyebilirsiniz') }}
                        </div>
                      </template>
                    </b-form-group>
                    <hr>
                    <b-form-group

                      :label="$t('Mail Ekleri')"
                      label-for="attachments"
                    >
                      <b-form-file
                        id="attachments"
                        v-model="mailData.attachments"
                        :placeholder="$t('Dosyaları Seçiniz Veya Buraya Sürükleyiniz')"
                        :drop-placeholder="$t('Dosyayı Buraya Bırakınız')"
                        multiple
                      />
                    </b-form-group>
                    <validation-provider
                      #default="validationContext"
                      :name="$t('Konu')"
                      rules="required|min:2"
                    >
                      <b-form-group
                        :label="$t('Konu')"
                        label-for="subject"
                      >
                        <b-form-input
                          id="subject"
                          v-model="mailData.subject"
                          autofocus
                          :state="getValidationState(validationContext)"
                          trim
                        />

                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>

                    <b-alert
                      variant="primary"
                      show
                    >
                      <h4 class="alert-heading">
                        Dinamik Parametreler
                      </h4>
                      <div class="alert-body">
                        <span>Mail göndermek istediğiniz kişilerin ad, soyad ve firma adını dinamik olarak mail içeriğine ekleyebilirsiniz. Bu parametreleri eklediğiniz alanlarda otomatik olarak ad, soyad veya firma bilgisi mail içeriğinde ilgili kısma yerleştirilecektir.</span>
                        <hr>
                        <b-button
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          variant="outline-primary"
                          size="sm"
                          @click="addDynamicParam('name')"
                        >

                          <span class="align-middle">{{ $t('Ad Ekle') }}</span>
                        </b-button>
                        <b-button
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          variant="outline-primary"
                          size="sm"
                          @click="addDynamicParam('surname')"
                        >

                          <span class="align-middle">{{ $t('Soyad Ekle') }}</span>
                        </b-button>
                        <b-button
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          variant="outline-primary"
                          size="sm"
                          @click="addDynamicParam('company_name')"
                        >

                          <span class="align-middle">{{ $t('Firma İsmi Ekle') }}</span>
                        </b-button>
                      </div>
                    </b-alert>
                    <validation-provider
                      #default="validationContext"
                      :name="$t('İçerik')"
                      rules="required|min:2"
                    >
                      <b-form-group
                        :label="$t('İçerik')"
                        label-for="content"
                      >

                        <quill-editor
                          ref="description"
                          v-model="mailData.content"
                          :options="snowOption"
                          :state="getValidationState(validationContext)"
                        />

                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                    <hr>
                    <b-form-group>
                      <b-form-checkbox
                        id="include_blacklist"
                        v-model="mailData.include_blacklist"
                        class="custom-control-warning"
                        name="check-button"
                        :value="true"
                        :unchecked-value="false"
                      >
                        {{ $t('Kara Listede Bulunan E Posta Adreslerini Hariç Tut') }}
                      </b-form-checkbox>
                      <hr>
                    </b-form-group>
                    <!-- Form Actions -->
                    <div class="d-flex mt-2">
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        class="mr-2"
                        type="submit"
                      >
                        {{ $t('Gönderim İşlemini Başlat') }}
                      </b-button>
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        v-b-modal.modal-1
                        variant="outline-primary"
                      >
                        {{ $t('E-Posta Önizlemesi') }}
                      </b-button>
                    </div>
                  </b-form>

                </validation-observer>
              </b-col>
            </b-row>

          </b-card>
        </b-col>
      </b-row>

    </b-overlay></div>
</template>

<script>
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import {
  BCard, BRow, BCol, VBModal, BButton, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BAlert, BFormTags, BModal, BFormFile, BFormCheckbox, BOverlay, BProgress, BCardText,
} from 'bootstrap-vue'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import { quillEditor } from 'vue-quill-editor'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import router from '@/router'
import emailPreview from './EmailPreview.vue'
import emailStoreModule from '../emailStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BModal,
    BProgress,
    BCardText,
    emailPreview,
    BAlert,
    BOverlay,
    BFormFile,
    BFormCheckbox,
    BCol,
    BFormTags,
    BButton,
    flatPickr,
    BFormInvalidFeedback,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BForm,
    vSelect,
    quillEditor,
    BFormGroup,
    BFormInput,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      postUploadingProgress: 0,
      showOverlay: false,
      required,
      showMarkAsReturnedModal: false,
      returnCompletionDate: '',
      showEditSidebar: false,
    }
  },
  mounted() {
    const today = this.$moment()
    this.mailData.send_time = today.format()
  },
  methods: {
    onShown() {
      // Focus the cancel button when the overlay is showing

    },
    onHidden() {
      // Focus the show button when the overlay is removed

    },
    emailListValidation(email) {
      const mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      if (email.match(mailformat)) {
        return true
      }

      return false
    },
    addDynamicParam(type = '') {
      const { quill } = this.$refs.description

      const length = quill.selection.savedRange.index
      if (type === 'name') {
        quill.insertText(length, '[%name%]')
      } else if (type === 'surname') {
        quill.insertText(length, '[%surname%]')
      } else if (type === 'company_name') {
        quill.insertText(length, '[%company_name%]')
      }
    },
    selectAllCategories(select = false) {
      this.mailData.selected_categories = []
      if (select) {
        this.emailCategoryData.forEach(val => {
          this.mailData.selected_categories.push(val.value)
        })
      }
    },
    selectAllUsers(select = false) {
      this.mailData.selected_user_customers = []
      if (select) {
        this.usersData.forEach(val => {
          this.mailData.selected_user_customers.push(val.value)
        })
      }
    },
    selectAllCountries(select = false) {
      this.mailData.selected_countries = []
      if (select) {
        this.countryOptions.forEach(val => {
          this.mailData.selected_countries.push(val.value)
        })
      }
    },
    openEditSidebarForEditing(item) {
      this.selectedBankAccount = JSON.parse(JSON.stringify(item))
      this.showEditSidebar = true
    },
    openEditSidebarForAdd() {
      this.showEditSidebar = true
      this.selectedBankAccount = JSON.parse(JSON.stringify(this.blankMailData))
    },
    // eslint-disable-next-line no-unused-vars
    async  saveData() {
      const { value: email } = await this.$swal.fire({
        title: 'Gönderim Onayı',
        text: 'E-Posta gönderimini başlatabilmek için aşağıdaki kutucuğa 1234 yazıp onaylayınız. Gönderim işlemi başlatıldıktan sonra geri alınamaz.',
        input: 'text',
      })

      if (email === '1234') {
        const formData = new FormData()

        formData.append('from_user', this.mailData.from_user)
        formData.append('subject', this.mailData.subject)
        formData.append('content', this.mailData.content)
        formData.append('send_time', this.mailData.send_time)
        formData.append('send_pass', this.mailData.send_pass)
        formData.append('selected_categories', this.mailData.selected_categories)
        formData.append('selected_user_customers', this.mailData.selected_user_customers)
        formData.append('selected_countries', this.mailData.selected_countries)
        formData.append('email_address_list', this.mailData.email_address_list)
        this.mailData.attachments.forEach(val => {
          formData.append('attachments[]', val)
        })
        this.showOverlay = true
        store.dispatch('app-email/sendEmail',
          formData,
          {
            onUploadProgress: function (progressEvent) {
              // eslint-disable-next-line radix
              this.postUploadingProgress = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100))
            }.bind(this),
          })
          // eslint-disable-next-line no-unused-vars
          .then(response => {
            this.showOverlay = false
            this.$refs.refFormObserver.reset()
            const { data } = response.data
            this.resetMailData()
            this.$swal({
              title: this.$t('Gönderim İşlemi Başlatıldı'),
              text: this.$t(`E posta gönderim işlemi servise başarıyla iletildi. Toplam alıcı adres: ${data.total_mails} adet. `),
              icon: 'success',
              showCancelButton: true,
              confirmButtonText: this.$t('Gönderim Durumunu Görüntüle'),
              customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ml-1',
              },
              buttonsStyling: false,
            }).then(result => {
              if (result.value) {
                router.push({ name: 'email-log-details', params: { id: data.email_data_id } })
              }
            })
            this.showSuccessMessage()
          })
          .catch(() => {
            this.showOverlay = false
            this.showErrorMessage()
          })
      }
    },
    // eslint-disable-next-line no-unused-vars
    deleteData(index, id) {
      this.$swal({
        title: this.$t('Emin Misiniz ?'),
        text: this.$t('Bu Banka Hesabını Silmek İstediğinizden Emin Misiniz ? (Bu işlem geri alınamaz.)'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Silme İşlemini Onayla'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch('app-proposal/deleteBankAccount', { id })
            .then(() => {
              this.bankAccounts.splice(index, 1)
              this.showSuccessMessage()
            })
            .catch(() => {
              this.showErrorMessage()
            })
        }
      })
    },

    showSuccessMessage() {
      this.$bvToast.toast(this.$t('İşlem Başarılı'), {
        title: this.$t('İşlem Başarılı'),
        variant: 'success',
        solid: false,
      })
    },
    showErrorMessage() {
      this.$bvToast.toast(this.$t('İşlem Başarısız'), {
        title: this.$t('İşlem Başarısız'),
        variant: 'danger',
        solid: false,
      })
    },
  },
  setup() {
    const APP_STORE_MODULE_NAME = 'app-email'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, emailStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })
    const mailData = ref([])
    const countryOptions = ref([null])
    const usersData = ref([null])
    const emailCategoryData = ref([null])
    const blankMailData = ref({
      id: 0,
      from_user: 0,
      subject: '',
      content: '',
      send_time: null,
      send_pass: '',
      selected_categories: [],
      selected_user_customers: [],
      selected_countries: [],
      email_address_list: [],
      attachments: [],
      include_blacklist: true,

    })
    // eslint-disable-next-line no-undef

    const myUserData = JSON.parse(localStorage.getItem('userData'))
    blankMailData.value.from_user = myUserData.id
    store.dispatch('app-email/fetchEmailCategories', { list_type: 'all' })
      .then(response => {
        const arr = []
        response.data.data.forEach(val => arr.push({ label: val.name, value: val.id }))
        emailCategoryData.value = arr
      })
    store.dispatch('app/fetchCountries', {})
      .then(response => {
        const arr = []
        response.data.data.forEach(val => arr.push({ label: val.name, value: val.code }))
        countryOptions.value = arr
      })
    store
      .dispatch('app/fetchUsers', {
        list_type: 'all',
      })
      .then(response => {
        const { users } = response.data.data
        const arr = []
        users.forEach(val => {
          arr.push({ label: `${val.name} (${val.email})`, value: val.id })
        })

        usersData.value = arr
      })
      .catch(() => {
      })
    const resetMailData = () => {
      mailData.value = JSON.parse(JSON.stringify(blankMailData.value))
    }
    resetMailData()
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetMailData)
    return {
      mailData,
      blankMailData,
      refFormObserver,
      getValidationState,
      countryOptions,
      resetForm,
      usersData,
      emailCategoryData,
      resetMailData,
      snowOption: {
        theme: 'snow',
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style scoped>
.per-page-selector {
  width: 90px;
}
.list-group-item {
  transition: all 1s
}
#add-new-bank-account-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
